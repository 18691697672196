import CtaSection from "../components/sections/CtaSection";
import GeneralHeader from "../components/global/GeneralHeader";
import Layout from "../components/global/Layout";
import QuoteSection from "../components/sections/QuoteSection/QuoteSection";
import styled from 'styled-components';
import React from "react";
import ValuePropsSection from "../components/sections/ValuePropsSection";
import StepsSection from "../components/page-specific/How-Grubhub-Works/StepsSection";
import FaqAccordionSection from "../components/sections/FaqAccordionSection";

const CateringCorporate = ({ location }) => {
  
  const valueProps = [
    {
      headline:"Catering made easy",
      subheadline:"Hosting a lunch and learn? Feeding a business meeting? Our food delivery services allow you to order catering as platters or individually boxed meals.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649355822/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Catering/EXPORT-Catering-ValueProp1.png",
    },
    {
      headline:"Feed any occasion, big or small",
      subheadline:"Preorder meals in advance or order on demand for same-day meetings. Perfect for in-office lunches and events!",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649353678/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Group%20Ordering/EXPORT-GroupOrder-ValueProp2.png",
    },
    {
      headline:"Optimized search features",
      subheadline:"Use our search filter to find restaurants near you that will individually box everyone’s meal.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649355822/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Catering/EXPORT-Catering-ValueProp3.png",
    },
    {
      headline:"Cut food waste and overspending",
      subheadline:"Order for a specific number of people you plan on feeding.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933621/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/C-GroupOrdering/EXPORTS-Illustrations-GroupOrdering-DeliveryBag-1-Citrus.png",
    },
    {
      headline:"Plan ahead with order scheduling features",
      subheadline:"Reduce day-of stress by placing your breakfast, lunch or dinner catering orders in advance.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652234572/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Catering/EXPORT-Catering-ValueProp2.png",
    },
    {
      headline:"24/7 Support",
      subheadline:"Receive access to our dedicated corporate care and client success teams so you can have peace of mind with every catering order.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1688003057/GH-Desktop-Computer-Avatar_2_quqe6e.png",
    },
  ]

  const steps = [
    {
      headline: "Sign up",
      description: "As the market pioneer in catering meals for large teams, our experts will help you build a tailored meal plan.",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231547/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/EXPORT-HowItWorks-Step1.png"
    },
    {
      headline: "Place order",
      description: "Filter through cuisine types and local restaurants that will cater meals, including the option to find those that can individually box catered meals.",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231547/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/EXPORT-HowItWorks-Step2.png"
    },
    {
      headline: "Eat delicious food",
      description: "Get your catering order delivered to the office or your event venue for your team to enjoy.",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/B-HowItWorks/EXPORTS-Illustrations-HowItWorks-Step3-Food-Citrus.png"
    }
  ]

  const faqs = [
    {
      header: "What do you serve at a lunch meeting?",
      description: `<span>Grubhub thinks business lunches should serve delicious, easy to eat food like individually-boxed sushi or salads. With a <a href="/">Grubhub Corporate Account</a> you can cater a business lunch that satisfies every taste. From salad to tacos, we've got your team covered with a variety of options from the nation’s largest network of local restaurant partners.</span>`
    },
    {
      header: "Does Grubhub deliver catering?",
      description: `<span>Grubhub delivers individually-boxed catering meals. A <a href="/">Grubhub corporate account</a> makes it easy to <a href="/catering/">cater to every taste</a>. From platters to individually boxed meals, our network of caterers can prepare your favorites for a business lunch delivered by <a href="https://www.grubhub.com">Grubhub</a>.</span>`
    }
  ]

  const onBeforeSubmit = () => {
    if(typeof window !== 'undefined' && typeof window.uet_report_conversion === 'function') {
      window.uet_report_conversion();
    }
  }

  return (
    <Layout 
      location={location} 
      title={"Corporate Catering | Grubhub Corporate"} 
      description={"Want to make catering for your next team lunch safe and easy? Learn about Grubhub Corporate Account catering options including individually boxed meals."}
      className="catering-page"
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ways to order",
        pageName: "corporate catering",
        brand: "grubhub"
      }}
    >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader 
        headline="Cater your next team meeting or corporate event."
        preheader=""
        textColor="var(--color-secondary)" 
        background="rgba(16,143,87,0.1)"
        formID={1999}
        businessEmailsUsage={true}
        businessEmailUTMLink='https://www.grubhub.com/lets-eat?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_catering__bing'
        onBeforeSubmit={onBeforeSubmit}
        backgroundColorForm='#FFF'
        formHeading="Sign up to learn more"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1688399174/HERMES/Miscellaneous/CateringSEMLandingPage.png"
      />
      <ValuePropsSection 
        headline="Delight your team with delicious meals"  
        valueProps={valueProps}
        layout="vertical"
      />
      <QuoteSection 
        quote='"Grubhub makes corporate catering so easy! The restaurant selections are fabulous and we can usually find any kind of cuisine to accommodate any request or diet restriction."'
        author="Carol Keesee"
        position="Planning Operations Manager at GSD&M"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1650461901/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Catering/PARTNERLOGOS-GSDM.png"
        backgroundColor="rgba(16,143,87,0.05)"
      />
      <StepsSection headline="Explore the industry's most flexible catering and meal perks platform" steps={steps} />
      <ValuePropsSection
        headline="Delivering happiness is our #1 priority"
        layout="horizontal"
        backgroundColor="#F9F9F9"
        valueProps={[
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Easiest for employees",
            subheadline:"Ordering catering is simple and easy. There’s no learning curve, which means your team will enjoy ordering good food from day one. Plus, Grubhub has the most ways to order, so your team can order whenever and however they want."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Best for office managers",
            subheadline:"The most group ordering options. Controlled, reduced costs. Guaranteed on-time catering delivery at the lowest price or we make it right – from breakfast and lunch to company parties. All different ways to say: get added peace of mind with Grubhub Corporate."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Built for businesses",
            subheadline:"Since 1999, Grubhub Corporate has been proudly serving corporate clients across America. The first to feed a hybrid workforce, we bring you cost-effective and flexible solutions that fit your team’s evolving catering needs."
          }
        ]}
      />
      <CtaSection ctaText="Get started" to="#general-header" headline="Discover the industry leader in corporate catering, lunch delivery services, and flexible meal benefits" backgroundColor="#FFF"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="white"/>
    </Layout>
  )
}

export default CateringCorporate

const StyledNavbarOnlyLogo = styled.header`
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 10;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;